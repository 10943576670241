import {dateSuffix, days, daysShort, months, monthsShort} from './helpers';
import spacetime from "spacetime";

export default function () {
    let config = window.searchWidget;
    const errorStyle = 'font-size: 15px; background-color: #dc3545; color: #fff; padding: 5px;';

    config.errorStyle = errorStyle;

    // required search widget values go here
    const searchWidgetInterface = [
        'cities',
        'hotels'
    ];
    // if you want a required setting to be disabled by another
    const searchWidgetInterfaceOmitter = {
        // value removes -> key
        'disableCities': 'cities'
    };
    Object.keys(searchWidgetInterfaceOmitter).forEach( val => {
        if ( config[val] != undefined && config[val] == true) {
            const omit = searchWidgetInterfaceOmitter[val];
            const omitIndex = Object.values(searchWidgetInterface).indexOf(omit);
            searchWidgetInterface.splice(omitIndex, 1);
        }
    });
    // list of required setting not set in the config
    let undefinedConfigNames = [];
    searchWidgetInterface.forEach((requiredSearchConfigName) => {
        if ( ! config[requiredSearchConfigName]) {
            undefinedConfigNames.push(requiredSearchConfigName);
        }
    });
    // prints the required setting for the config to the console
    if (undefinedConfigNames.length) {
        undefinedConfigNames.forEach(undefinedConfigName => {
            console.error('%c "' + undefinedConfigName + '" is required on the "searchWidget" Object', errorStyle);
        });
    }

    /**
     * Set defaults
     */
    const defaultSettings = {
        occupancyUrl: '/api/hotel-occupancys',
        maxAdults: 5,
        minAdults: 1,
        maxChildren: 4,
        maxInfants: 4,
        minChildren: 0,
        minInfants: 0,
        rooms: 10,
        nights: 10,
        selectedNights: 1,
        selectedRooms: 1,
        selectedHotel: 0,
        selectedCity: 0,
        selectedChildAges: {1: 0},
        childAgesArray: 16
    };

    for (let key in defaultSettings) {
        if (config[key]) {
            continue;
        }
        if (typeof defaultSettings[key] == "function") {
            config[key] = defaultSettings[key]();
        } else {
            config[key] = defaultSettings[key];
        }
    }

    /**
     * Overwrite simple setting
     */
    const overwriteSettings = {
        maxChildren: function () {
            let maxChildren = {};
            for (let i = 1; i <= config.rooms; i++) {
                maxChildren[i] = config.maxChildren;
            }
            return maxChildren;
        }
    }

    for (let key in overwriteSettings) {
        config[key] = overwriteSettings[key]();
    }
    config.maxInfants = config.maxChildren;

    // room selection
    const fillSections = [
        {
            selected: 'selectedAdults',
            min: 'minAdults',
            max: 'maxAdults'
        },
        {
            selected: 'selectedChildren',
            min: 'minChildren',
            max: 'maxChildren'
        },
        {
            selected: 'selectedInfants',
            min: 'minChildren', // same a children
            max: 'maxChildren' // same a children
        }
    ];
    fillSections.forEach(s => {
        config[s.selected] = (function () {
            let obj = {};
            for (let i = 1; i <= config.rooms; ++i) {
                if ( ! config[s.selected] || ! config[s.selected][i]) {
                    obj[i] = parseInt(config[s.min]);
                } else {
                    obj[i] = parseInt(config[s.selected][i]);
                }
                if (obj[i] > config[s.max]) {
                    console.error('%c The "' + s.selected + '" is greater than the max "'+ s.max + '" allowed', config.errorStyle);
                }
            }
            return obj;
        })();
    });

    /**
     * Date picker config
     */
    const defaultDatePickerSetting = {
        arrival: new Date(),
        departure: null,
        mode: 'single',
        fullscreenMobile: false,
        closeAfterSelect: true,
        maxDays: 365,
        monthsToShow: 2,
        dateDisplay: function (arrival, departure) {
            return arrival;
        }
    }

    if ( ! config.datepickerConfig) {
        config.datepickerConfig = defaultDatePickerSetting;
    } else {
        Object.keys(defaultDatePickerSetting).forEach(key => {
            if ( ! config.datepickerConfig[key]) {
                config.datepickerConfig[key] = defaultDatePickerSetting[key];
            }
        });
    }

    /**
     * @Helpers
     */
    config.dateSuffix = dateSuffix;
    config.days = days;
    config.months = months;
    config.daysShort = daysShort;
    config.monthsShort = monthsShort;
    spacetime.extend({
        dateMonthYearFormat() {
            let date = daysShort[this.day()] + ' ';
            date += this.date() + dateSuffix(this.date());
            date += ' ' + this.format('month-short');
            date += ' ' + this.year();
            return date;
        }
    });
    window.spacetime = spacetime;

    return config;
}
