import {parseKeyValuesToInt, getLargestNumber} from './helpers';

export default {

    data() {
      return {
          occupancyByHotel: {}
      }
    },


    watch: {
        selectedAdults: {
            handler(selectedAdultsInRooms) {
                this.updateEachRoom();
            },
            deep: true
        },
        selectedChildren: {
            handler() {
                this.updateEachRoom();
            },
            deep: true
        }
    },

    methods: {
        setHotelOccupancyForHotel(id) {
            /**
             * If we are disabling the use of fetching occupancy from a hotel
             * or the hotel id is 0, we'll default to the settings in the config
             */
            if (this.config.disableOccupancyUrl || id == 0) {
                this.maxAdults = this.config.maxAdults;
                this.maxChildren = this.config.maxChildren;
                this.maxInfants = this.maxChildren;
                this.updateEachRoom();
                return;
            }
            const hotelOccupancy = this.occupancyByHotel[id];
            if ( ! hotelOccupancy) {
                return;
            }

            this.maxAdults = Object.values(hotelOccupancy).reduce((currentVal, occupancySetting) => {
                if (currentVal < occupancySetting.ADULTS) {
                    currentVal = occupancySetting.ADULTS;
                }
                return currentVal;
            }, 1);

            if (this.config.minAdults > this.maxAdults) {
                console.error(`%c The minAdults [${this.config.minAdults}] is large than maxAdults [${this.maxAdults}]`, this.config.errorStyle);
            }

            this.updateEachRoom();
        },

        sortByHotelId(occupancy) {
            occupancy.forEach(occupancySetting => {
               if ( ! this.occupancyByHotel[occupancySetting.HOTELS_ID]) {
                   this.occupancyByHotel[occupancySetting.HOTELS_ID] = [];
               }
               this.occupancyByHotel[occupancySetting.HOTELS_ID].push(occupancySetting);
            });
        },

        updateEachRoom() {
            let selectedHotelOccupancy = this.occupancyByHotel[this.selectedHotel];
            // no occupancy found default
            if ( ! selectedHotelOccupancy) {
                console.warn(`No occupancy found for ${this.selectedHotel}, defaulting`);
                selectedHotelOccupancy = [];
                for (let i = 1; i <= this.maxAdults; ++i) {
                    selectedHotelOccupancy.push({
                        ADULTS: i,
                        MAX_CHILDREN: this.maxAdults - i
                    });
                }
            }


            for (let i = 1; i <= this.selectedRooms; ++i) {
                if (this.selectedAdults[i] > this.maxAdults) {
                    this.selectedAdults[i] = this.maxAdults;
                }

                const selectedAdultsForRoom = parseInt(this.selectedAdults[i]);
                
                for (let item of selectedHotelOccupancy) {
                    if (item.ADULTS == selectedAdultsForRoom) {
                        this.maxChildren[i] = item.MAX_CHILDREN;
                        this.maxInfants[i] = item.MAX_CHILDREN;
                    }
                }

                if (this.selectedChildren[i] > this.maxChildren[i]) {
                    this.selectedChildren[i] = this.maxChildren[i];
                }

                const maxInfants = this.maxChildren[i] - this.selectedChildren[i]
                if (this.selectedInfants[i] >= maxInfants) {
                    this.selectedInfants[i] = maxInfants;
                }
            }
        },

        getMaxAdults() {
            let adults = [];
            for (let i = this.config.minAdults; i <= this.maxAdults; i++) {
                adults.push(i);
            }
            return adults;
        },

        getMaxChildren(roomIndex) {
            let children = [];
            for (let i = this.config.minChildren; i <= this.maxChildren[roomIndex]; i++) {
                children.push(i);
            }
            return children;
        },

        getMaxInfants(roomIndex) {
            let infants = [];
            for (let i = this.config.minInfants; i <= (this.maxInfants[roomIndex] - this.selectedChildren[roomIndex]); i++) {
                infants.push(i);
            }
            return infants;
        },
        getAdultsInRoom(roomIndex) {
            return this.selectedAdults[roomIndex];
        },
        getChildrenInRoom(roomIndex) {
            return this.selectedChildren[roomIndex];
        },
        getInfantsInRoom(roomIndex) {
            return this.selectedInfants[roomIndex];
        },
        /**
         * gets css classes for styling the add adults button
         * @param int roomIndex
         */
        getClassesForAddAdults(roomIndex) {
            return {
                'disabled': this.getAdultsInRoom(roomIndex) >= getLargestNumber(this.getMaxAdults())
            };
        },
        getClassesForSubAdults(roomIndex) {
            return {
                'disabled': this.config.minAdults == this.getAdultsInRoom(roomIndex)
            }
        },
        getClassesForAddChildren(roomIndex) {
            return {
                'disabled': this.getChildrenInRoom(roomIndex) >= getLargestNumber(this.getMaxChildren(roomIndex))
            };
        },
        getClassesForSubChildren(roomIndex) {
            return {
                'disabled': this.config.minChildren == this.getChildrenInRoom(roomIndex)
            }
        },
        getClassesForAddInfants(roomIndex) {
            return {
                'disabled': this.getInfantsInRoom(roomIndex) >= getLargestNumber(this.getMaxInfants(roomIndex))
            }
        },
        getClassesForSubInfants(roomIndex) {
            return {
                'disabled': this.config.minInfants == this.getInfantsInRoom(roomIndex)
            }
        }

    },

    mounted() {
        if (this.config.disableOccupancyUrl) {
            return;
        }

        fetch(this.config.occupancyUrl).then( res => res.json()).then(occupancy => {
            /**
             * set all values to be integers before sorting
             */
            occupancy = occupancy.map(occupancySetting => {
                return parseKeyValuesToInt(occupancySetting, Object.keys(occupancySetting));
            });
            this.sortByHotelId(occupancy);
            this.setHotelOccupancyForHotel(this.selectedHotel);
        }).catch( e => {
            console.error('%c Hotel Occupancy failed to load', this.config.errorStyle);
        });
    }

}
