

export const dateSuffix = function (number) {
    var d = number % 10;
    return (~~ (number % 100 / 10) === 1) ? 'th' :
        (d === 1) ? 'st' :
        (d === 2) ? 'nd' :
        (d === 3) ? 'rd' : 'th';
}

export const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export const daysShort = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thur',
    'Fri',
    'Sat'
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Api',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export function isNumeric(value) {
    return ! isNaN(parseInt(value));
}


export function parseKeyValuesToInt(object, keys) {
    for (let key of keys) {
        object[key] = parseInt(object[key]);
    }
    return object;
}

/**
 * Finds the largest number in an array
 * @param array
 * @return int
 */
export function getLargestNumber(array) {
    return Math.max.apply(0, array);
}
