/**
 * Polyfills for older browsers
 */

const Vue = window.Vue;
import searchWidgetConfig from "./inc/search-widget-config";
import HotelOccupancy from './inc/hotel-occupancy';
import DatePicker from "./inc/date-picker";
import ExpiryCard from './expiry-card';

(function () {
    if (document.querySelectorAll('expiry-card').length == 0) {
        return;
    }

    new Vue({
        el: 'expiry-card',
        components: {
            ExpiryCard
        }
    });
})();

(function () {
    // not all pages that use the ibe-core will have a search widget so return here
    if (! window.searchWidget) {
        return;
    }

    const config = searchWidgetConfig();
    const searchComponentElements = document.querySelectorAll('[search-component], #search-component');
    
    if ( ! searchComponentElements.length) {
        console.error('%c ibe-core.js included but is missing #search-component', config.errorStyle);
        return;
    }

    const mixins = [
        HotelOccupancy
    ];

    if (config.extend) {
        mixins.push(config.extend);
    } else if (config.vue) {
        mixins.push(config.vue);
    }

    searchComponentElements.forEach((element) => {
        window.searchComponent = new Vue({
            el: element,
            components: {
                'date-picker': DatePicker
            },
            mixins,
            data() {
                return {
                    config: config,
                    // adds all the config options
                    ...config
                }
            },
            computed: {
                allCities() {
                    return config.cities;
                },
                allHotels() {
                    return config.hotels;
                },
                hotelsByIdKey() {
                    let hotels = {};
                    for (let hotel of config.hotels) {
                        hotels[hotel.id] = hotel;
                    }
                    return hotels;
                }
            },

            watch: {
                selectedHotel(val, oldVal) {
                    const selectedHotel = this.hotelsByIdKey[val];
                    if ( ! this.config.disableCities && selectedHotel && selectedHotel.city != 0) {
                        this.selectedCity = selectedHotel.city;
                    }
                    this.setHotelOccupancyForHotel(val);
                },

                selectedCity(val, oldVal) {
                    if (this.config.disableCities) {
                        return;
                    }
                    if (val == 0) {
                        this.hotels = this.allHotels;
                        return;
                    }
                    this.hotels = Object.values(this.allHotels).filter(hotel => {
                        return hotel.city == val;
                    });

                    // there is more than one hotel for this city so add the "All Hotels option"
                    if (this.hotels.length > 1) {
                        this.hotels.unshift(this.hotelsByIdKey[0]);
                    }

                    if (this.hotelsByIdKey[this.selectedHotel].city != val) {
                        this.selectedHotel = this.hotels[0].id;
                    }

                }
            },

            methods: {
                /**
                 * Gets all data from the form inputs,
                 * this will allow us to validate on the client
                 * @return Object
                 */
                getAllFormFields() {
                    let formData = {};
                    const form = new FormData(this.$el);
                    for (let key of form.keys()) {
                        formData[key] = form.get(key);
                    }
                    return formData;
                },
                getNameFeild(name, roomIndex) {
                    return name + '[' + roomIndex + ']';
                },
                getNameFieldForChildAges(name, roomIndex, children) {
                    return name + '[' + roomIndex + ']' + '[' + children + ']';
                },
                showRoom(roomIndex) {
                    return this.selectedRooms >= roomIndex;
                },
                isSelectedNight(night) {
                    return this.selectedNights == night;
                },
                isSelectedCity(index) {
                    return this.selectedCity == index;
                },
                isSelectedHotel(index) {
                    return this.selectedHotel == index;
                },
                isSelectedRooms(index) {
                    return this.selectedRooms == index;
                },
                isSelectedAdults(roomIndex, index) {
                    return this.selectedAdults[roomIndex] == index;
                },
                isSelectedChildren(roomIndex, index) {
                    return this.selectedChildren[roomIndex] == index;
                },
                isSelectedInfants(roomIndex, index) {
                    return this.selectedInfants[roomIndex] == index;
                },
                isSelectedChildAges(roomIndex, children, value) {
                    return (
                        this.selectedChildAges[roomIndex] &&
                        this.selectedChildAges[roomIndex][children] == value
                    );
                },
                addRooms(event) {
                    const newValue = this.selectedRooms + 1;
                    if (newValue <= this.rooms) {
                        this.selectedRooms = newValue;
                    }
                },
                subRooms(event) {
                    const newValue = this.selectedRooms - 1;
                    if (newValue > 0) {
                        this.selectedRooms = newValue;
                    }
                },
                addAdult(event, roomIndex) {
                    const newValue = this.selectedAdults[roomIndex] + 1
                    if (newValue <= this.maxAdults) {
                        this.selectedAdults[roomIndex] = newValue;
                    }
                },
                subAdult(event, roomIndex) {
                    const newValue = this.selectedAdults[roomIndex] - 1;
                    if (newValue >= config.minAdults) {
                        this.selectedAdults[roomIndex] = newValue;
                    }
                },
                addChild(event, roomIndex) {
                    const newValue = this.selectedChildren[roomIndex] + 1;
                    if (newValue <= this.maxChildren[roomIndex]) {
                        this.selectedChildren[roomIndex] = newValue;
                    }
                    this.updateEachRoom();
                },
                subChild(event, roomIndex) {
                    const newValue = this.selectedChildren[roomIndex] - 1;
                    if (newValue >= config.minChildren) {
                        this.selectedChildren[roomIndex] = newValue;
                    }
                    this.updateEachRoom();
                },
                addInfant(event, roomIndex) {
                    const newValue = this.selectedInfants[roomIndex] + 1;
                    const maxInfants = this.getMaxInfants(roomIndex).length;
                    if (newValue < maxInfants) {
                        this.selectedInfants[roomIndex] = newValue;
                    }
                },
                subInfant(event, roomIndex) {
                    const newValue = this.selectedInfants[roomIndex] - 1;
                    if (newValue >= config.minInfants) {
                        this.selectedInfants[roomIndex] = newValue;
                    }
                },
                getTotalPeople() {
                    let total = 0;
                    for (let i = 1; i <= this.selectedRooms; ++i) {
                        total += this.selectedAdults[i];
                        total += this.selectedChildren[i];
                        total += this.selectedInfants[i];
                    }
                    return total;
                },
                getClassesForAddRooms() {
                    return {
                        'disabled': this.selectedRooms == this.config.rooms
                    }
                },
                getClassesForSubRooms() {
                    return {
                        'disabled': this.selectedRooms == 1
                    }
                }
            }
        });
    });
})();






