<template>
    <div class="datepicker-trigger">
        <input class="form-control" type="text" :id="triggerUID" :value="dateDisplay(arrival, departure)" readonly>
        <input type="hidden" name="arrival" v-model="arrival">
        <input type="hidden" v-if="isRangeMode" name="departure" v-model="departure">
        <airbnb-style-datepicker
            :trigger-element-id="triggerUID"
            :mode="config.datepickerConfig.mode"
            :fullscreen-mobile="config.datepickerConfig.fullscreenMobile"
            :date-one="arrival"
            :date-two="departure"
            :mobileHeader="config.datepickerConfig.mobileHeader"
            :minDate="minDate"
            :endDate="maxDate"
            :showActionButtons="true"
            :showShortcutsMenuTrigger="false"
            :monthsToShow="monthsToShow"
            @date-one-selected="onDateOneSelected"
            @date-two-selected="onDateTwoSelected"
        ></airbnb-style-datepicker>
    </div>
</template>

<script>
    /**
     * for some reason AirbnbStyleDatepicker requires a date formatting package that is supposedly optional
     * but if you import it, it craps out
     * So have to include it before this js file and then take it from the window
     */
    window.Vue.use(vueAirbnbStyleDatepicker, window.searchWidget ? window.searchWidget.datepickerOptions : {});

    export default {
        name: "datepicker",
        props: {
            config: {
                required: true,
                type: Object
            },
        },
        data() {
            const { datepickerConfig } = this.config;
            return {
                arrival: datepickerConfig.arrival,
                departure: datepickerConfig.departure,
                dateDisplay: datepickerConfig.dateDisplay,
                monthsToShow: datepickerConfig.monthsToShow
            }
        },
        computed: {
            triggerUID() {
              return 'trigger-' + Math.floor(Math.random() * 99999);
            },
            isRangeMode() {
              return this.config.datepickerConfig.mode == 'range';
            },
            minDate() {
                const today = new Date();
                return new Date(today.setDate(today.getDate() - 1));
            },
            maxDate() {
                const today = new Date();
                return new Date(today.setDate(today.getDate() + this.config.datepickerConfig.maxDays));
            }
        },
        methods: {
            onDateOneSelected(val) {
                this.arrival = val;
            },
            onDateTwoSelected(val) {
                this.departure = val;
            }
        }
    }
</script>

